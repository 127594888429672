import { useTranslation } from "react-i18next";

// assets
import emptyBalanceImg from "../../assets/images/buy-and-supply-balance-empty-state.png"
import emptyDataImg from "../../assets/images/buy-and-supply-empty-state.png"
import emptyPendingOrdersImg from "../../assets/images/buy-and-supply-pending-orders-empty-state.png"

const EmptyState = ({ mode, data }) => {
    const { t } = useTranslation();

    return (
        <div className={`buy-and-supply__empty-state ${data === "balance" ? " buy-and-supply__empty-state--balance" : data === "pending orders" ? " buy-and-supply__empty-state--pending-orders" : ""} py-5 my-5 d-flex flex-column align-items-center`}>
            <img src={data === "items" ? emptyDataImg : data === "balance" ? emptyBalanceImg : emptyPendingOrdersImg} alt="" className="d-block img-fluid" />
            <p className="fsize-4 fw-bold">{t("buyAndSupply.pageIsEmpty")}</p>
            <p className="fsize-5 fw-500 mb-0">
                {t(`buyAndSupply.${data === "balance" ? "checkBalanceLater" : data === "pending orders" ? "emptyPendingOrders" : mode === "buy" ? "checkItemsLater" : "checkTypesLater"}`)}
            </p>
        </div>
    );
}

export default EmptyState;
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { baseUrl } from "../../services";

const TypeCard = ({ data, onSell }) => {
    const { dir } = useSelector((state) => state.language);
    const { t } = useTranslation();

    return (
        <article dir={dir} className="merchant__types__type">
            <img
                src={data ? baseUrl + data.typeImage : ""}
                alt=""
                className="merchant__types__type__image d-block w-100 object-fit-cover"
            />
            <div className="merchant__types__type__info">
                <h2 className="text--secondary-8 fw-bold fsize-5">
                    {t("merchant.merchantID")} : #{data?.merchantCode}
                </h2>
                <h2 className="text--secondary-8 fw-bold fsize-5">{data?.type}</h2>
                <div dir={dir} className="merchant__types__type__info__price-holder d-flex">
                    <div dir={dir} className="merchant__types__type__info__price-holder__title text-white fw-bold fsize-11 d-flex justify-content-center align-items-center">
                        <span>{t("merchant.pricePerKilo")}</span>
                    </div>
                    <div className="merchant__types__type__info__price-holder__value flex-fill text--secondary-8 d-flex flex-column align-items-center justify-content-center">
                        <span className="fw-bold fsize-5">{data?.perKiloPrice}</span>
                        <span className="fw-500 fsize-11">{t("merchant.LEPerKG")}</span>
                    </div>
                </div>
            </div>
            <button
                type="button"
                onClick={() => onSell(data)}
                className="d-block text-white fw-bold fsize-6 text-center w-100 border-0 outline-none shadow-none"
            >
                {t("merchant.sell")}
            </button>
        </article>
    );
}

export default TypeCard;
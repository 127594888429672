import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import "react-toastify/dist/ReactToastify.css";
import RouterFile from "./Routes";
import WebsiteTitle from "./helpers/WebsiteTitle";
import { getServToken } from "./services";
import "./App.css";
// import PostPay from "./modules/Paymob/PostPay";
// import "./firebase";
// import Notifications from "./modules/Notification/Notifications";

function App() {
  const { authorization } = useSelector((state) => state.auth);
  const { lang, dir } = useSelector((state) => state.language);
  const { i18n } = useTranslation();
  getServToken(authorization?.token);
  const location = useLocation();
  const title = location.pathname.split("/")[1];

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [])

  return (
    <div
      className="App"
      lang={lang}
    >
      <Helmet>
        <html lang={lang} dir={dir}></html>
      </Helmet>
      <ToastContainer />
      <WebsiteTitle title={title} />
      {/* <Notifications /> */}
      <RouterFile />
      {/* <PostPay /> */}
    </div>
  );
}

export default App;

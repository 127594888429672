import { getResource, postResource, putResource } from "./services";
import store from "./store/index";

function getCurrentLang() {
  const state = store.getState();
  const { lang } = state.language;
  return lang;
}

export function signUpApi(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/User/Register?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}
// export function signInApi(data, onSuccess, onFail) {
//   const path = `api/User/Login?language=${lang}`;
//   postResource(path, data, onSuccess, onFail);
// }
export function signInApi(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/User/SendVerificationCode?mobile=${data?.mobile}&language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}
export function confirmCodeApi(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/User/ConfirmCode?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}
export function contactUs(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/v1/senEmailForMechanicalStaticWebsite`;
  postResource(path, data, onSuccess, onFail);
}
export function getAllItemsApi(onSuccess, onFail) {
  const lang = getCurrentLang();
  // const path = "api/Item/Items?lang=en";
  const path = `api/Item/ItemsForUser?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function addItemToCartApi(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Cart?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}
export function getAllUnitsApi(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Item/Units?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function getOrderStatusApi(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/User/OrderStatus?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function getPaymentTypesApi(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/User/PaymentTypes?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function requestPaymentApproval(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/User/RequestPaymentApproval?language=${lang}`;
  postResource(path, {}, onSuccess, onFail);
}
export function getUserBalanceInfoApi(onSuccess, onFail) {
  const lang = getCurrentLang();
  // const path = "api/Order/BalanceInfo?language=${lang}";
  const path = `api/Order/BalanceInfoGroupedByItemCategory?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function getUserBalanceInfoByTypeApi(typeId, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Order/BalanceInfoGroupedByItemCategoryByType?typeId=${typeId}&language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function getUserPendingOrdersApi(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Order/GetPendingOrdersForUser?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function getUserAcceptedOrdersApi(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Order/AcceptedCreditCardPaymentsByAdminForUser?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function completeCreditCardPayment(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Order/CompaleteCreditCardPayment?orderId=${data.orderId}&language=${lang}`;
  postResource(path, {}, onSuccess, onFail);
}
export function getUserBalanceApi(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/User/Balance?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function getPurchesRequestForUser(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Admin/PurchaseRequestForUserGroupedByItem?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
// export function getPurchesRequestForUser(onSuccess, onFail) {
//   const lang = getCurrentLang();
//   const path = `api/Admin/PurchaseRequestsForUser?language=${lang}`;
//   getResource(path, onSuccess, onFail);
// }
export function getTermsAndConditions(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/User/GetTermsAndConditions?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function getBalanceInfoForExchangesApi(id, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Order/BalanceInfoForExchange?purchaseItemId=${id}&language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function placeOrderApi(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Order/PlaceOrder?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}
export function acceptOfferByUserApi(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Admin/AcceptOfferByUserForItem?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}
export function acceptOfferByUserByOrderList(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Admin/AcceptOfferByUserForItemByOrderList?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}
export function updateFireBaseTokenApi(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}
export function placeOrderForExchangeApi(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Order/PlaceOrderForExchange?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}
export function getWhatsAppContact(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/User/ContactUsWhatsAppNumber?language=${lang}&forGreenMatter=true`;
  getResource(path, onSuccess, onFail);
}
export function getProfitAndPercentage(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/User/ProphitpercentageAndGreenMatterCommission?language=${lang}&purchaseRequestId=${data.purchaseRequestId}&quantity=${data.quantity}`;
  getResource(path, onSuccess, onFail);
}
export function getProfitAndPercentageByOrderList(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/User/ProphitpercentageAndGreenMatterCommissionByOrderList?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}
export function getAuctions(status, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Auction/AllAuctionsForUser?language=${lang}&status=${status}`;
  getResource(path, onSuccess, onFail);
}
export function getAuctionDetails(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Auction/AuctionDetailsForUser?language=${lang}&status=${data.status}&auctionId=${data.auctionId}`;
  getResource(path, onSuccess, onFail);
}
export function updateAuctionWinner(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Auction/UserEarnedAuction?language=${lang}`;
  putResource(path, data, onSuccess, onFail);
}
export function endAuction(auctionId, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Auction/EndAuction?language=${lang}&id=${auctionId}`;
  putResource(path, {}, onSuccess, onFail);
}
export function getUserGroups(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Group/UserGroupsForProfile?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function getAllActiveGroups(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Group/AllActiveGroupsForWebsite?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function incrementGroupBudgetRequest(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Group/GroupIncrementRequest?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}
export function acceptRejectGroupBudgetRequest(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Group/AcceptOrRejectIncrementRequest?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}
export function getLuckyHoursItem(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Item/LuckyHourItemsForUser?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function getMoneyCollecionTypes(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/User/MoneyCollectionType?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function getStoreItems(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/GMStore/StoreItemsForUser?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function getBalanceInfoForStoreItem(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/GMStore/BalanceInfoForStoreItem?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function placeOrderForStoreItem(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/GMStore/PlaceOrderForStoreItem?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}
export function getUserAuctionRequests(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Auction/AuctionInvitations?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function getUserAuctionParticipations(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Auction/UserAuctionParticpations?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function acceptRejectAuctionRequest(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Auction/AcceptOrRejectAuctionInvitation?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}
export function placeOrderForAuction(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Auction/PlaceOrder?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}
/* Start buy and supply */
export function getBuyItems(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Item/SupplyANdBuyBuyItemsForUser?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function getSupplyItems(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Item/SupplyANdBuySupplyItemsForUser?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function placeBuyOrder(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/BuyAndSupply/PlaceOrder?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}
export function sendSupplyRequest(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/BuyAndSupply/SupplyItemByUser?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}
export function getBuyAndSupplyPendingOrders(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/BuyAndSupply/PendingOrdersForuser?language=${lang}`;
  getResource(path, onSuccess, onFail);
}
/* End buy and supply */

/* Start merchant */
export function getMerchatTypes(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Merchant/MerchantTypesForUser?language=${lang}`;
  getResource(path, onSuccess, onFail);
}

export function getProfitAndPercentageWhenSellingToMechant(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Merchant/ProphitpercentageAndGreenMatterCommissionByOrderList?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}

export function sellToMerchant(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Merchant/SellToMerchant?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}
/* End merchant */ 
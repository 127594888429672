import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { toastError } from "../../helpers/toasters";
import { getMerchatTypes } from "../../network";

// components
import TypeCard from "./TypeCard";
import SellModal from "./SellModal";
import EmptyState from "../BuyAndSupply/EmptyState";

const Merchant = () => {
    const [data, setData] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [openSellModal, setOpenSellModal] = useState(false);
    const [renderMe, setRenderMe] = useState(false);
    const [loading, setLoading] = useState(true);
    const { dir } = useSelector((state) => state.language);
    const { t } = useTranslation();

    const handleCancelSellModal = () => {
        setOpenSellModal(false)
        setSelectedType(null)
    }

    const handleOpenSellModal = (type) => {
        setSelectedType(type);
        setOpenSellModal(true);
    }

    useEffect(() => {
        setLoading(true)
        getMerchatTypes(
            (res) => {
                setLoading(false)
                if (res.success) {
                    setData(res.data)
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                setLoading(false)
                toastError(res?.data?.error)
            }
        )
    }, [dir, renderMe])

    return (
        <section dir={dir} className="merchant">
            <header className="merchant__header d-flex justify-content-center align-items-center">
                <div>
                    <h1 className="text-center green-text fsize-1 ">{t("nav.merchant")}</h1>
                    <p className="mb-0 fw-500 text-white fsize-6 text-center">
                        {t("merchant.description")}
                    </p>
                </div>
            </header>
            <Spin size="large" className="w-100" spinning={loading}>
                {data ? (
                    data.length > 0 ? (
                        <div className="merchant__types">
                            <div dir="ltr" className="row justify-content-center justify-content-lg-start m-0">
                                {data.map((type, indx) => {
                                    return (
                                        <div key={type.id} className={`col-sm-8 col-md-12 col-lg-6 ${indx % 2 === 0 ? "ps-lg-0" : "pe-lg-0"}`}>
                                            <TypeCard
                                                data={type}
                                                onSell={handleOpenSellModal}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <EmptyState mode="" data={"items"} />
                    )
                ) : (
                    ""
                )}
            </Spin>
            {openSellModal && (
                <SellModal
                    open={openSellModal}
                    selectedType={selectedType}
                    onCancel={handleCancelSellModal}
                    onSubmit={() => {
                        setRenderMe(!renderMe)
                        handleCancelSellModal()
                    }}
                />
            )}
        </section>
    );
}

export default Merchant;
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Form, Modal, Spin } from "antd";
import { toastError, toastSuccess } from "../../../helpers/toasters";
import {
    getProfitAndPercentageWhenSellingToMechant,
    getUserBalanceInfoByTypeApi,
    sellToMerchant
} from "../../../network";

// components
import SellCard from "./SellCard";
import ProfitInfo from "./ProfitInfo";

const SellModal = ({
    open,
    onCancel,
    onSubmit,
    selectedType,
}) => {
    const [loading, setLoading] = useState(false);
    const [loadingProfit, setLoadingProfit] = useState(false);
    const [invalid, setInvalid] = useState(true)
    const [profit, setProfit] = useState(0);
    const [profitPercentage, setProfitPercentage] = useState(0);
    const [commissionPercentage, setCommissionPercentage] = useState(0);
    const [commission, setCommission] = useState(0);
    const [priceAfterDeduction, setPriceAfterDeduction] = useState(0);
    const [typeItems, setTypeItems] = useState(null);
    const { dir } = useSelector((state) => state.language);
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const handleInputOnChange = (value, updatedItem) => {
        setTypeItems((prevData) => {
            const soldItems = prevData.map((item) => {
                if (updatedItem.orderId === item.orderId) {
                    return {
                        ...updatedItem,
                        inputValue: +value
                    };
                } else {
                    return item;
                }
            });
            return soldItems;
        });
    };

    const handleSelectItem = (event, orderId) => {
        setTypeItems((prevData) => (
            prevData.map((item) => {
                if (item.orderId === orderId) {
                    if (event.target.checked) {
                        form.setFieldValue(`weight-${item.orderId}`, item.remainingQuantity)
                    }
                    return {
                        ...item,
                        isSelected: event.target.checked,
                        inputValue: event.target.checked ? item.remainingQuantity : 0,
                    }
                } else {
                    return item
                }
            })
        ));
    };

    const getProfit = () => {
        setLoadingProfit(true)
        const payload = {
            purchaseRequestId: selectedType.id
        }
        const orders = typeItems.filter((item) => item.isSelected).map((item) => {
            return { orderId: item.orderId, sealedQuantity: item.inputValue }
        })
        payload.orders = orders;

        getProfitAndPercentageWhenSellingToMechant(
            payload,
            (res) => {
                setLoadingProfit(false)
                if (res.success) {
                    setProfit(res.data.prophitValue)
                    setProfitPercentage(res.data.prophitPercentage)
                    setCommissionPercentage(res.data.commissionPercentage)
                    setCommission(res.data.greenMatterCommission)
                    setPriceAfterDeduction(res.data.sellingPriceAfterCommissionDeduction)
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                setLoadingProfit(false)
                toastError(res.message)
            })
    }

    const handleFinish = () => {
        if (loading || loadingProfit || invalid) return;
        setLoading(true);

        const payload = { id: selectedType.id };
        const orders = typeItems.filter((item) => item.isSelected).map((item) => {
            return { orderId: item.orderId, sealedQuantity: item.inputValue }
        })
        payload.orders = orders;

        sellToMerchant(
            payload,
            (res) => {
                setLoading(false);
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setLoading(false);
                toastError(res?.data?.error);
            }
        );
    };

    useEffect(() => {
        if (typeItems) {
            let valid = false;
            for (let item of typeItems) {
                if (item.isSelected) {
                    if (
                        item.inputValue &&
                        item.inputValue > 0.1 &&
                        item.inputValue <= item.remainingQuantity
                    ) {
                        valid = true;
                    } else {
                        valid = false;
                        break;
                    }
                }
            }
            setInvalid(!valid)
            if (valid) {
                getProfit()
            } else {
                setProfit(0)
                setCommissionPercentage(0)
                setProfitPercentage(0)
                setCommission(0)
            }
        }
    }, [typeItems])

    useEffect(() => {
        if (selectedType) {
            getUserBalanceInfoByTypeApi(
                selectedType.typeId,
                (res) => {
                    if (res.success) {
                        const updatedItems = res.data.items.map((item) => {
                            return {
                                ...item, isSelected: false, inputValue: ""
                            }
                        })
                        setTypeItems(updatedItems)
                    } else {
                        toastError(res.message)
                    }
                }, (res) => {
                    toastError(res.message);
                })
        }
    }, [selectedType]);

    return (
        <Modal
            width={"auto"}
            centered
            open={open}
            wrapClassName="merchant__sell-modal"
            destroyOnClose={true}
            maskClosable={false}
            onCancel={onCancel}
            closable={true}
            footer={false}
        >
            <h1 className="text-center fsize-13 green-text fw-bold">
                {t("merchant.sell")} {selectedType?.type}
            </h1>
            {typeItems ? (
                typeItems.length > 0 ? (
                    <Form
                        dir={dir}
                        form={form}
                        onFinish={handleFinish}
                    >
                        <div className="merchant__sell-modal__types-holder d-flex flex-column">
                            {typeItems?.map((type) => (
                                <SellCard
                                    key={type.orderId}
                                    data={type}
                                    formRef={form}
                                    onSelect={handleSelectItem}
                                    onInputChange={handleInputOnChange}
                                />
                            ))}
                        </div>
                        <div className="merchant__sell-modal__profit-holder row m-0">
                            <div className="col-lg-6 px-0">
                                <div className="merchant__sell-modal__profit-col mx-auto mx-lg-0">
                                    <ProfitInfo
                                        title={t("notificationModal.profitPercentage")}
                                        value={profitPercentage}
                                        invalid={invalid}
                                        loading={loadingProfit}
                                        prefix={"%"}
                                    />
                                    <ProfitInfo
                                        title={t("notificationModal.profit")}
                                        value={profit}
                                        invalid={invalid}
                                        loading={loadingProfit}
                                        prefix={t("notificationModal.egp")}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 px-0 d-lg-flex justify-content-lg-end">
                                <div className="merchant__sell-modal__profit-col mx-auto mx-lg-0">
                                    <ProfitInfo
                                        title={t("notificationModal.commissionPercentage")}
                                        value={commissionPercentage}
                                        invalid={invalid}
                                        loading={loadingProfit}
                                        prefix={"%"}
                                    />
                                    <ProfitInfo
                                        title={t("notificationModal.commission")}
                                        value={commission}
                                        invalid={invalid}
                                        loading={loadingProfit}
                                        prefix={t("notificationModal.egp")}
                                    />
                                </div>
                            </div>
                            <div className="col-12 px-0 d-lg-flex justify-content-lg-center">
                                <ProfitInfo
                                    title={t("notificationModal.priceAfterCommissionDeduction")}
                                    value={priceAfterDeduction}
                                    invalid={invalid}
                                    loading={loadingProfit}
                                    prefix={t("notificationModal.egp")}
                                />
                            </div>
                        </div>
                        <Button
                            htmlType="submit"
                            disabled={invalid || loadingProfit || loading}
                            loading={loading}
                            className="merchant__sell-modal__submit-btn d-block fsize-5 text-white mx-auto fw-bold border-0"
                        >
                            {t("merchant.sell")}
                        </Button>
                    </Form>
                ) : (
                    <>
                        <p className="merchant__sell-modal__empty-msg fw-500 fsize-5 text-center">{t("merchant.noBalanceMSG")}</p>
                        <Button
                            htmlType="button"
                            onClick={onCancel}
                            className="merchant__sell-modal__submit-btn d-block fsize-5 text-white mx-auto fw-bold"
                        >
                            {t("merchant.ok")}
                        </Button>
                    </>
                )
            ) : (
                <Spin size="large" className="w-100" spinning={!typeItems} />
            )}
        </Modal>
    );
}

export default SellModal;
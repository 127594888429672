import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Checkbox, Form, InputNumber } from "antd";
import { baseUrl } from "../../../services";
import { handleAllowNumbersOnly } from '../../../helpers/helpfulFunctions';

// assets
import image from "../../../assets/images/default-product-img.png";

const SellCard = ({
    data,
    formRef,
    onSelect,
    onInputChange,
}) => {
    const { dir } = useSelector((state) => state.language);
    const { t } = useTranslation();

    const handleWeightValidation = (_, value) => {
        onInputChange(value, data);
        if (!value && value !== 0) {
            return Promise.reject(new Error(t("notificationModal.requiredQuantity")))
        } else if (value <= 0.1) {
            return Promise.reject(new Error(t("notificationModal.correctQuantity")))
        } else if (value > data?.remainingQuantity) {
            return Promise.reject(new Error(t("merchant.notEnoughBalanceMSG")))
        }
        return Promise.resolve()
    }

    return (
        <article
            className="merchant__sell-modal__types-holder__type d-flex flex-column flex-md-row align-items-center"
        >
            <Checkbox
                checked={data?.isSelected}
                onChange={(e) => {
                    if (!e.target.checked) {
                        formRef.resetFields([`weight-${data.orderId}`])
                    }
                    onSelect(e, data.orderId)
                }}
                className="merchant__sell-modal__types-holder__type__checkbox"
            />
            <img
                src={data?.image?.length ? baseUrl + data.image : image}
                alt=""
                className="merchant__sell-modal__types-holder__type__image d-block"
            />
            <div className="align-self-stretch flex-fill d-flex flex-column justify-content-between">
                <div className="row m-0 flex-column flex-md-row align-md-items-center">
                    <div className={`col-md-6 px-0 ${dir === "ltr" ? "pe-md-3" : "ps-md-3"}`}>
                        <h2
                            title={data?.item}
                            className={`merchant__sell-modal__types-holder__type__name text-truncate text--secondary-8 fsize-14 fw-500 mb-1 mb-md-0 text-center ${dir === "ltr" ? "text-md-start" : "text-md-end"}`}
                        >
                            {data?.item}
                        </h2>
                    </div>
                    <div className="col-md-6 px-0 mb-4 mb-md-0">
                        <div>
                            <p className={`dark-gray fw-500 fsize-11 mb-0 text-center ${dir === "ltr" ? "text-md-start" : "text-md-end"}`}>
                                {data?.purchaseDate}
                            </p>
                            <p className={`text--secondary-8 fw-500 fsize-11 mb-0 text-center ${dir === "ltr" ? "text-md-start" : "text-md-end"}`}>
                                {t("home.exchangeModal.totalWeight")}: {data?.remainingQuantity}{" "}{t("home.exchangeModal.kg")}
                            </p>
                            <p className={`text--secondary-8 fw-500 fsize-11 mb-0 text-center ${dir === "ltr" ? "text-md-start" : "text-md-end"}`}>
                                {t("home.exchangeModal.price")}: {data ? parseFloat(data.purchasePrice).toFixed(2) : ""} {" "}{t("home.exchangeModal.le")}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-10 px-0">
                        <p dir={dir} className={`text--secondary-8 fw-bold fsize-11 mb-1 text-center ${dir === "rtl" ? "text-md-end" : "text-md-start"}`}>
                            {t("merchant.enterWeight")}
                        </p>
                        <Form.Item
                            name={`weight-${data?.orderId}`}
                            rules={data?.isSelected ? [{
                                validator: handleWeightValidation
                            }] : []}
                        >
                            <InputNumber
                                min={0}
                                className="w-100 d-block merchant__sell-modal__types-holder__type__weight-input fsize-11 fw-bold"
                                disabled={!data?.isSelected}
                                controls={false}
                                keyboard={false}
                                onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                            />
                        </Form.Item>
                    </div>
                </div>
            </div>
        </article>
    );
};

export default SellCard;

import { Spin } from "antd";

const ProfitInfo = ({
    title,
    value,
    invalid,
    loading,
    prefix
}) => {
    return (
        <p className="merchant__sell-modal__profit-col__info mb-0 main-dark-text fw-500 fsize-11 text-center">
            {title}: {loading ? <Spin /> : `${!invalid ? value : 0} ${prefix}`}
        </p>
    );
}

export default ProfitInfo;
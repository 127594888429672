import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Dropdown } from "antd";
import { ROUTES } from "../../constants/_routes";
import {
  getAuctions,
  getPurchesRequestForUser,
  getTermsAndConditions,
  getUserBalanceInfoApi,
  getUserPendingOrdersApi,
  getWhatsAppContact,
} from "../../network";
import { toastError } from "../../helpers/toasters";
import { listenToAuctionRequests, listenToLiveAuctions, listenToUserCount } from "../../firebaseService";
import { doc, getDoc, setDoc } from "firebase/firestore";
import db from "../../firebase";
import { calculateCounter } from "../../helpers/helpfulFunctions";
// import { baseUrl } from "../../services";

// assets
import greenMatterFinalLogo from "../../assets/images/logo.png";
// import flashIcon from "../../assets/icons/auction-flash.svg"

// icons components
import HomeIcon from "./Icons/HomeIcon";
import AboutIcon from "./Icons/AboutIcon";
import OrdersIcon from "./Icons/OrdersIcon";
import BalanceIcon from "./Icons/BalanceIcon";
import BuyerIcon from "./Icons/BuyerIcon";
import ContactUsIcon from "./Icons/ContactUsIcon";
import TermsIcon from "./Icons/TermsIcon";
import SignOutIcon from "./Icons/SignOutIcon";
import ProfileIcon from "./Icons/ProfileIcon";
import LuckyHourIcon from "./Icons/LuckyHourIcon"
import StoreIcon from "./Icons/StoreIcon";
import MoreIcon from "./Icons/MoreIcon";
// import BiddersIcon from "./Icons/BiddersIcon";
// import AuctionRequestsIcon from "./Icons/AuctionRequests";
import BuyAndSupplyIcon from "./Icons/BuyAndSupplyIcon";
import BuyIcon from './Icons/BuyIcon';
import SupplyIcon from "./Icons/SupplyIcon";

// components
import NotificationModal from "../Notification/NotificationModal";
import BalanceModal from "../Modals/BalanceModal";
import PendingModal from "../Modals/PendingModal";
import LanguageBtn from "../Common/LanguageBtn/LanguageBtn";
import OffersDrawer from "../Modals/OffersDrawer/OffersDrawer";
import TermAndConditionsModal from "../Authentications/SignUp/TermsAndConditionsModal";
import ProfileDrawer from "../Modals/ProfileDrawer";
import AuctionRequestsDrawer from "../Modals/AuctionRequestsDrawer";
import MerchantIcon from "./Icons/MerchantIcon";

function NavBar() {
  const { authorization } = useSelector((state) => state?.auth);
  const isAuth = authorization.token;
  const [offerCount, setOfferCount] = useState(0);
  // const [auctionRequestsCount, setAuctionRequestsCount] = useState(0);
  const [isBalanceVisible, setIsBalanceVisible] = useState(false);
  const [isPendingOrdersVisible, setIsPendingOrdersVisible] = useState(false);
  const [isOffersVisible, setIsOffersVisible] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showProfileDrawer, setShowProfileDrawer] = useState(false);
  const [showAuctinsRequestsDrawer, setShowAuctinsRequestsDrawer] = useState(false);
  const [orders, setOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [purchase, setPurchase] = useState([]);
  const [render, setRender] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  // const [liveAuctionsCount, setLiveAuctionsCount] = useState(0)
  const [terms, setTerms] = useState("");
  const [showMoreBtn, setShowMoreBtn] = useState(window.innerWidth >= 992);
  const { dir } = useSelector((state) => state.language);
  const { t } = useTranslation();
  const location = useLocation()
  const navToggleBtn = useRef();
  // const isLive = baseUrl === "https://api.thegreenmatter.com/"

  const handleCloseMenu = () => {
    if (navToggleBtn.current.getAttribute("aria-expanded") === "true") {
      navToggleBtn.current.click();
    }
  }

  const handleContact = () => {
    const windowReference = window.open();
    getWhatsAppContact((res) => {
      if (res.success) {
        windowReference.location = `https://api.whatsapp.com/send?phone=${res.data}`;
      } else {
        toastError(res.message);
      }
    }, (res) => {
      toastError(res.message)
    })
  }

  const moreDropdown = [
    {
      label:
        <li dir={dir} className="nav-item" onClick={handleCloseMenu}>
          <button
            type="button"
            onClick={() => setShowProfileDrawer(true)}
            className="w-100 p-0 bg-transparent border-0 outline-none shadow-none d-flex gap-2 gap-md-1 align-items-center">
            <ProfileIcon />
            <span className="recycle-nav__nav-titles text-center fsize-12">
              {t("nav.profile")}
            </span>
          </button>
        </li>,
      key: 0
    },
    {
      label:
        <li className="nav-item">
          <NavLink
            to={ROUTES.ABOUT}
            role="button"
            dir={dir}
            className="nav-link p-0 text-decoration-none d-flex gap-2 gap-md-1 align-items-center"
          >
            <AboutIcon />
            <span className="recycle-nav__nav-titles text-center fsize-12">
              {t("nav.about")}
            </span>
          </NavLink>
        </li>,
      key: 1
    },
    {
      label:
        <li dir={dir} className="nav-item" onClick={handleCloseMenu}>
          <button
            type="button"
            onClick={() => setIsTermsModalOpen(true)}
            className="w-100 p-0 bg-transparent border-0 outline-none shadow-none d-flex gap-2 gap-md-1 align-items-center">
            <TermsIcon />
            <span className="recycle-nav__nav-titles text-center fsize-12">{t("nav.terms")}</span>
          </button>
        </li>
      ,
      key: 2
    },
    {
      label:
        <li dir={dir} className="nav-item" onClick={handleCloseMenu}>
          <Link
            to={"#"}
            onClick={handleContact}
            // to="https://api.whatsapp.com/send?phone=201143847684"
            className="nav-link p-0 d-flex gap-2 gap-md-1 align-items-center"
          >
            <ContactUsIcon />
            <span className="recycle-nav__nav-titles text-center fsize-12">
              {t("nav.contactUs")}
            </span>
          </Link>
        </li>
      ,
      key: 3
    },
    {
      label:
        <li dir={dir} className="nav-item" onClick={handleCloseMenu}  >
          <LanguageBtn inNavMoreMenu />
        </li>
      ,
      key: 4
    },
    {
      label:
        <li dir={dir} className="nav-item">
          <Link to={ROUTES.LOGOUT} className="nav-link p-0 d-flex gap-2 gap-md-1 align-items-center">
            <SignOutIcon />
            <span className="recycle-nav__nav-titles text-center fsize-12">
              {t("nav.signout")}
            </span>
          </Link>
        </li>
      ,
      key: 5
    },
  ]

  const getUserPendingOrders = () => {
    getUserPendingOrdersApi(
      (success) => {
        if (success?.success) {
          setPendingOrders(success.data);
        } else {
          toastError(success.message);
        }
      },
      (fail) => {
        toastError(fail.message);
      }
    );
  };

  const getUserBalanceInfo = () => {
    getUserBalanceInfoApi(
      (success) => {
        if (success?.success) {
          setOrders(success.data);
        } else {
          toastError(success.message);
        }
      },
      (fail) => {
        toastError(fail.message);
      }
    );
  };

  const purchesRequestForUser = () => {
    getPurchesRequestForUser(
      (success) => {
        if (success?.success) {
          setPurchase(success.data);
        } else {
          toastError(success.message);
        }
      },
      (fail) => {
        toastError(fail.message);
      }
    );
  };

  const handleToggleBalance = () => {
    setIsBalanceVisible(true);
    getUserBalanceInfo();
  };

  const handleTogglePendingOrders = () => {
    setIsPendingOrdersVisible(true);
    getUserPendingOrders();
  };

  const handleRenderParents = () => {
    setRender((prev) => !prev);
  };

  const onCancel = () => {
    setIsBalanceVisible(false);
    setIsPendingOrdersVisible(false);
  };

  const onNotCancel = () => {
    setShowNotificationModal(false);
  };

  const onOffersClose = () => {
    setIsOffersVisible(false);
  };

  const handleShowOffers = async () => {
    setIsOffersVisible(true);
    //update count by 0 in fire store
    await setDoc(doc(db, `users`, authorization?.userId), {
      unReadMessagesCount: 0,
    }, { merge: true });
    setRender((prev) => !prev);
  };

  // const handleShowAuctionRequests = async () => {
  //   setShowAuctinsRequestsDrawer(true);
  //   await setDoc(
  //     doc(db, `users`, authorization?.userId),
  //     { auctionNotifications: 0 },
  //     { merge: true }
  //   );
  // }

  const handleOffer = (offer, accept) => {
    setSelectedItem({ ...offer, isAccepted: accept });
    setShowNotificationModal(true);
  };

  const handleBuyingFromStore = () => {
    setShowNotificationModal(false)
    setIsOffersVisible(false)
  }

  useLayoutEffect(() => {
    function updateSize() {
      setShowMoreBtn(window.innerWidth >= 992);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [])

  useEffect(() => {
    getTermsAndConditions(
      (success) => {
        if (success.success) {
          setTerms(success.data);
        } else {
          toastError(success.message);
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data?.message);
        } else {
          toastError();
        }
      }
    );
  }, [dir]);

  useEffect(() => {
    if (!authorization?.userId) return;

    const unsubscribeCount = listenToUserCount(
      authorization.userId,
      (newCount) => {
        setOfferCount(newCount);
      }
    );

    // const unsubscribeAuctionRequests = listenToAuctionRequests(
    //   authorization.userId,
    //   (newCount) => {
    //     setAuctionRequestsCount(newCount);
    //   }
    // );

    // const unsubscribeLiveAuctions = listenToLiveAuctions(
    //   (newCount) => {
    //     setLiveAuctionsCount(newCount)
    //   }
    // );

    return () => {
      // Unsubscribe when the component unmounts
      unsubscribeCount();
      // unsubscribeAuctionRequests();
      // unsubscribeLiveAuctions();
    };
  }, [authorization]);

  useEffect(() => {
    getUserBalanceInfo();
    getUserPendingOrders();
    purchesRequestForUser();
    // getUserBalance();
  }, [render, dir]);

  // useEffect(() => {
  //   getAuctions(
  //     1,
  //     (res) => {
  //       if (res.success) {
  //         res.data.forEach(async (auction) => {
  //           const auctionDocRef = doc(db, "auctions", auction.id)
  //           const auctionDocSnap = await getDoc(auctionDocRef);
  //           if (!auctionDocSnap.exists()) {
  //             await setDoc(auctionDocRef, {
  //               lastBid: 0,
  //               startAt: auction.startDate,
  //               lastBidBy: "",
  //               active: true
  //             });
  //           } else if (auctionDocSnap.data().active) {
  //             const auctionDataInFB = auctionDocSnap.data()
  //             const { minutes, seconds } = calculateCounter(auctionDataInFB.startAt);
  //             if (minutes <= 0 && seconds <= 0) {
  //               setDoc(doc(db, "auctions", auction.id), { active: false }, { merge: true });
  //             }
  //           }
  //         })
  //       } else {
  //         toastError(res.message)
  //       }
  //     },
  //     (res) => {
  //       toastError(res.message)
  //     }
  //   )
  // }, [location])

  return (
    <div className="recycle-nav" dir={dir}>
      {!isAuth ? (
        <img
          className={`recycle-nav__logo ${dir} d-block`}
          src={greenMatterFinalLogo}
          alt="greenMatter"
        />
      ) : (
        <nav className="navbar navbar-expand-lg">
          <div className="navbar__main-holder container-fluid px-3 px-lg-2 px-xxl-4 py-1 py-md-0 py-xxl-3">
            <Link to={ROUTES.GLOBAL} className="nav-link p-0 h-100">
              <img
                className={`recycle-nav__logo ${dir} d-block`}
                src={greenMatterFinalLogo}
                alt="greenMatter"
              />
            </Link>
            <button
              type="button"
              className="navbar-toggler fs-3 text-white px-2 rounded-1 outline-0 border-0 shadow-none me-md-3"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
              ref={navToggleBtn}
            >
              <svg
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
              </svg>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNavDropdown"
            >
              <ul
                className={`navbar-nav ${dir === "ltr" ? "pe-lg-3" : "ps-lg-3"}`}
              >
                <li className="nav-item">
                  <NavLink
                    to={ROUTES.GLOBAL}
                    role="button"
                    onClick={handleCloseMenu}
                    className="nav-link p-0 text-decoration-none d-flex flex-md-column gap-2 gap-md-1 align-items-center"
                  >
                    <HomeIcon />
                    <span className="recycle-nav__nav-titles fsize-12">
                      {t("nav.home")}
                    </span>
                  </NavLink>
                </li>
                {!showMoreBtn ? (
                  <li className="nav-item">
                    <NavLink
                      to={ROUTES.ABOUT}
                      role="button"
                      onClick={handleCloseMenu}
                      className="nav-link p-0 text-decoration-none d-flex flex-md-column gap-2 gap-md-1 align-items-center"
                    >
                      <AboutIcon />
                      <span className="recycle-nav__nav-titles text-center fsize-12">
                        {t("nav.about")}
                      </span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
                <li className="nav-item" onClick={handleCloseMenu}>
                  <div
                    role="button"
                    onClick={handleTogglePendingOrders}
                    className="d-flex flex-md-column gap-2 gap-md-1 align-items-center"
                  >
                    <OrdersIcon />
                    <span className="recycle-nav__nav-titles text-center fsize-12">
                      {t("nav.pendingOrders")}
                    </span>
                  </div>
                </li>
                <li className="nav-item" onClick={handleCloseMenu}>
                  <div
                    role="button"
                    onClick={handleToggleBalance}
                    className="d-flex flex-md-column gap-2 gap-md-1 align-items-center"
                  >
                    <BalanceIcon />
                    <span className="recycle-nav__nav-titles fsize-12">
                      {t("nav.balance")}
                    </span>
                  </div>
                </li>
                <li className="nav-item" onClick={handleCloseMenu}>
                  <div
                    role="button"
                    onClick={handleShowOffers}
                    className="d-flex flex-md-column gap-2 gap-md-1 align-items-center"
                  >
                    <div className="position-relative d-flex">
                      {offerCount > 0 ? (
                        <span className="recycle-nav__offers-count active-offer">
                          {offerCount > 99 ? "99+" : offerCount}
                        </span>
                      ) : (
                        ""
                      )}
                      <BuyerIcon />
                    </div>
                    <span className="recycle-nav__nav-titles text-center fsize-12">
                      {t("nav.buyers")}
                    </span>
                  </div>
                </li>
                <li className="nav-item">
                      <Dropdown
                        overlayClassName="buy-and-supply__dropdown"
                        menu={{
                          items: [
                            {
                              label: (
                                <NavLink
                                  to="/buy-and-supply/buy"
                                  onClick={handleCloseMenu}
                                  className="nav-link buy-and-supply__dropdown__link p-0 text-decoration-none d-flex gap-2 gap-md-1 align-items-center"
                                >
                                  <BuyIcon />
                                  <span className="buy-and-supply__dropdown__link__title text-center fsize-12">
                                    {t("buyAndSupply.buy")}
                                  </span>
                                </NavLink>
                              ),
                              key: '0',
                            },
                            {
                              label: (
                                <NavLink
                                  to="/buy-and-supply/supply"
                                  onClick={handleCloseMenu}
                                  className="nav-link buy-and-supply__dropdown__link p-0 text-decoration-none d-flex gap-2 gap-md-1 align-items-center"
                                >
                                  <SupplyIcon />
                                  <span className="buy-and-supply__dropdown__link__title text-center fsize-12">
                                    {t("buyAndSupply.supply")}
                                  </span>
                                </NavLink>
                              ),
                              key: '1',
                            }
                          ]
                        }}
                        trigger={['click']}
                      >
                        <NavLink
                          to={ROUTES.BUYANDSUPPLY}
                          role="button"
                          onClick={(e) => {
                            e.preventDefault()
                          }}
                          className="nav-link p-0 text-decoration-none d-flex flex-md-column gap-2 gap-md-1 align-items-center"
                        >
                          <BuyAndSupplyIcon />
                          <span className="recycle-nav__nav-titles text-center fsize-12">
                            {t("nav.buyAndSupply")}
                          </span>
                        </NavLink>
                      </Dropdown>
                    </li>
                {/* {!isLive ? (
                  <>
                    <li className="nav-item" onClick={handleCloseMenu}>
                      <div
                        role="button"
                        onClick={handleShowAuctionRequests}
                        className="d-flex flex-md-column gap-2 gap-md-1 align-items-center"
                      >
                        <div className="position-relative">
                          {auctionRequestsCount > 0 ? (
                            <span className="recycle-nav__offers-count active-offer">
                              {auctionRequestsCount > 99 ? "99+" : auctionRequestsCount}
                            </span>
                          ) : (
                            ""
                          )}
                          <AuctionRequestsIcon />
                        </div>
                        <span className="recycle-nav__nav-titles text-center fsize-12">
                          {t("nav.auctionRequests")}
                        </span>
                      </div>
                    </li>
                    <li className={"nav-item"} onClick={handleCloseMenu}>
                      <div className="d-flex flex-md-column align-items-center">
                        <div className="position-relative">
                          <NavLink
                            to={ROUTES.BIDDERS}
                            role="button"
                            className="nav-link p-0 text-decoration-none d-flex flex-md-column gap-2 gap-md-1 align-items-center"
                          >
                            <BiddersIcon />
                            <span className="recycle-nav__nav-titles text-center fsize-12">
                              {t("nav.bidders")}
                            </span>
                          </NavLink>
                          {liveAuctionsCount > 0 ? (
                            <img src={flashIcon} alt="" className={`nav-item__flash-icon ${dir === "rtl" ? "nav-item__flash-icon--ar" : ""} position-absolute d-block img-fluid`} />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </li>
                  </>
                ) : (
                  ""
                )} */}
                <li className="nav-item">
                  <NavLink
                    to={ROUTES.STORE}
                    role="button"
                    onClick={handleCloseMenu}
                    className="nav-link p-0 text-decoration-none d-flex flex-md-column gap-2 gap-md-1 align-items-center"
                  >
                    <StoreIcon />
                    <span className="recycle-nav__nav-titles text-center fsize-12">
                      {t("nav.store")}
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={ROUTES.MERCHANT}
                    role="button"
                    onClick={handleCloseMenu}
                    className="nav-link p-0 text-decoration-none d-flex flex-md-column gap-2 gap-md-1 align-items-center"
                  >
                    <MerchantIcon />
                    <span className="recycle-nav__nav-titles text-center fsize-12">
                      {t("nav.merchant")}
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={ROUTES.LUCKYHOURS}
                    role="button"
                    onClick={handleCloseMenu}
                    className="nav-link p-0 text-decoration-none d-flex flex-md-column gap-2 gap-md-1 align-items-center"
                  >
                    <LuckyHourIcon />
                    <span className="recycle-nav__nav-titles text-center fsize-12">
                      {t("nav.luckyHours")}
                    </span>
                  </NavLink>
                </li>
                {!showMoreBtn ? (
                  <>
                    <li className="nav-item" onClick={handleCloseMenu}>
                      <button
                        type="button"
                        onClick={() => setShowProfileDrawer(true)}
                        className="w-100 p-0 bg-transparent border-0 outline-none shadow-none d-flex flex-md-column gap-2 gap-md-1 align-items-center">
                        <ProfileIcon />
                        <span className="recycle-nav__nav-titles text-center fsize-12">
                          {t("nav.profile")}
                        </span>
                      </button>
                    </li>
                    <li className="nav-item" onClick={handleCloseMenu}>
                      <Link
                        to={"#"}
                        onClick={handleContact}
                        // to="https://api.whatsapp.com/send?phone=201143847684"
                        className="nav-link p-0 d-flex flex-md-column gap-2 gap-md-1 align-items-center"
                      >
                        <ContactUsIcon />
                        <span className="recycle-nav__nav-titles text-center fsize-12">
                          {t("nav.contactUs")}
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item" onClick={handleCloseMenu}>
                      <button
                        type="button"
                        onClick={() => setIsTermsModalOpen(true)}
                        className="w-100 p-0 bg-transparent border-0 outline-none shadow-none d-flex flex-md-column gap-2 gap-md-1 align-items-center">
                        <TermsIcon />
                        <span className="recycle-nav__nav-titles text-center fsize-12">{t("nav.terms")}</span>
                      </button>
                    </li>
                    <li className="nav-item">
                      <Link to={ROUTES.LOGOUT} className="nav-link p-0 d-flex flex-md-column gap-2 gap-md-1 align-items-center">
                        <SignOutIcon />
                        <span className="recycle-nav__nav-titles text-center fsize-12">
                          {t("nav.signout")}
                        </span>
                      </Link>
                    </li>
                  </>
                ) : (
                  ""
                )}
                {!showMoreBtn ? (
                  <li className="nav-item" onClick={handleCloseMenu}  >
                    <LanguageBtn />
                  </li>
                ) : (
                  ""
                )}
                {showMoreBtn ? (
                  <Dropdown
                    menu={{
                      items: moreDropdown,
                    }}
                    trigger={['click']}
                    rootClassName="recycle-nav__more-dropdown"
                  >
                    <button
                      type="button"
                      className="recycle-nav__more-dropdown__toggle-btn nav-item bg-transparent border-0 outline-none shadow-none d-flex flex-md-column gap-2 gap-md-1 align-items-center"
                    >
                      <MoreIcon />
                      <span className="recycle-nav__nav-titles text-center fsize-12">
                        {t("nav.more")}
                      </span>
                    </button>
                  </Dropdown>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div >
        </nav >
      )
      }
      {
        isTermsModalOpen && (
          <TermAndConditionsModal
            isOpen={isTermsModalOpen}
            data={terms}
            onCancel={() => setIsTermsModalOpen(false)}
          />
        )
      }
      {
        isBalanceVisible && (
          <BalanceModal
            isModalOpen={isBalanceVisible}
            onCancel={onCancel}
            data={orders}
            title={"My Balance"}
          ></BalanceModal>
        )
      }
      {
        isPendingOrdersVisible && (
          <PendingModal
            isModalOpen={isPendingOrdersVisible}
            onCancel={onCancel}
            data={pendingOrders}
            title={"My Pending Orders"}
          ></PendingModal>
        )
      }
      {
        showNotificationModal && (
          <NotificationModal
            isOpen={showNotificationModal}
            onCancel={onNotCancel}
            selectedItem={selectedItem}
            handleRenderParents={handleRenderParents}
            onBuyingFromStore={handleBuyingFromStore}
          ></NotificationModal>
        )
      }
      {
        isOffersVisible && (
          <OffersDrawer
            data={purchase}
            onOffersClose={onOffersClose}
            isOffersVisible={isOffersVisible}
            dir={dir}
            handleOffer={handleOffer}
          />
        )
      }
      {
        showProfileDrawer && (
          <ProfileDrawer
            open={showProfileDrawer}
            dir={dir}
            onClose={() => {
              setShowProfileDrawer(false)
            }}
          />
        )
      }
      {showAuctinsRequestsDrawer && (
        <AuctionRequestsDrawer
          onClose={() => setShowAuctinsRequestsDrawer(false)}
          open={showAuctinsRequestsDrawer}
          dir={dir}
        />
      )}
    </div >
  );
}

export default NavBar;
